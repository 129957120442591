@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');

body {
    margin: 0px;
}

.App {
    font-family: 'Nunito', sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.Navbar {
    justify-content: space-evenly;
    background-color: hsl(168, 89%, 33%);
    margin-bottom: 10px;
}

h1 {
    color: hsl(45, 42%, 93%);
}

.App > h2 {
    font-weight: normal;
}

.input-field {
    height: 50%;
    border-radius: 5px;
    border: 5px solid hsl(45, 42%, 93%);
    background-color: hsl(45, 42%, 93%);
}

.countryCard {
    border-radius: 10px;
    background-color: hsl(168, 89%, 33%);
    color: #f4f0e4;
    margin: 5px;
    padding: 15px;
    min-width: auto;
    max-width: auto;
    width: 50%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;

    -webkit-box-shadow: -2px 1px 33px -20px hsla(0, 0%, 0%, 0.17);
    -moz-box-shadow: -2px 1px 33px -20px hsla(0, 0%, 0%, 0.17);
    box-shadow: -2px 1px 33px -20px hsla(0, 0%, 0%, 0.169);
}

.tab {
    border-radius: 5px;
    border-color: hsl(45, 42%, 93%);
    margin: 10px 0px;
}
.tab button {
    background-color: hsl(45, 42%, 93%);
    color: hsl(168, 89%, 33%);
    border: none;
    width: 100px;
    height: 30px;
}

.tab button:hover {
    background-color: hsl(168, 89%, 33%);
    color: hsl(45, 42%, 93%);
    border: solid;
    border-color: hsl(45, 42%, 93%);
}

.columnWidth {
    width: 50%;
}

.flag {
    display: block;
    border-radius: 10px;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    height: 150px;
    width: auto;
    -webkit-box-shadow: -2px 1px 33px -20px hsla(0, 0%, 0%, 0.169);
    -moz-box-shadow: -2px 1px 33px -20px hsla(0, 0%, 0%, 0.17);
    box-shadow: -2px 1px 33px -20px hsla(0, 0%, 0%, 0.17);
}

.currencyInput {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

svg text {
    fill: hsl(45, 42%, 93%);
    font-size: 0.8rem;
}

.graph-placeholder {
    width: 80%;
    height: 145px;
}
